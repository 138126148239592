import { Stack } from '@mui/material';
import { authApi, useGetDropdownNotificationsQuery } from 'apis/auth.api';
import NotificationCard from 'components/NotificationsListing/NotificationCard';
import { useEffect, useState } from 'react';
// import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from 'react-redux';
// import NotificationCard from './NotificationCard';

const DropdownNotificationListing = ({ gap,
  // unreadNotifications
}) => {
  const dispatch = useDispatch();
  const [next, setNext] = useState(1);
  const { data } = useGetDropdownNotificationsQuery(next);
  const { results: notifications = [], next: nextUrl, count = 0 } = data || {};

  // const fetchMoreData = () => {
  //   setNext(nextUrl);
  // }

  useEffect(() => {
    return () => {
      dispatch(authApi.util.invalidateTags(['DROPDOWN_NOTIFICATIONS']));
    }
  }, [dispatch])

  return (
    // <InfiniteScroll
    //   dataLength={count}
    //   next={fetchMoreData}
    //   hasMore={nextUrl !== null}
    //   loader={<>Loading ...</>}
    //   height={"340px"}
    // >
    <Stack direction="column" gap={gap} px={3}>
      {notifications.map((item) =>
        <NotificationCard key={item.id} {...item} />
      )}
    </Stack>
    // </InfiniteScroll>
  )
}

export default DropdownNotificationListing