import { authApi } from 'apis/auth.api';
import { onMessageListener } from 'init-fcm';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUnreadNotifications } from 'slices/user.slice';

const useListenNotifications = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Listen for incoming messages
    onMessageListener()
      .then((payload) => {
        console.log('Message received: ', payload);
        dispatch(setUnreadNotifications(payload));
        dispatch(authApi.util.invalidateTags(['NOTIFICATIONS']));
        // Handle incoming messages here
      })
      .catch((err) => console.log('Message listener error: ', err));
  }, []);
}

export default useListenNotifications