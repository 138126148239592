import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = initializeApp({
  apiKey: "AIzaSyB7mgHe6QIHx6iFf5i_97RwKmd2Xl5afDw",
  authDomain: "zyvly-9bf95.firebaseapp.com",
  projectId: "zyvly-9bf95",
  storageBucket: "zyvly-9bf95.appspot.com",
  messagingSenderId: "376586780908",
  appId: "1:376586780908:web:8ae00a514c934c767a25c9",
  measurementId: "G-NGLPTY3BTD"
})

export const messaging = getMessaging(firebaseConfig);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: "BAyXVJrE74Ph6-9fq2fymWK3Tl42uCV179p86Bpn7lgHfYJc1Uc4WglhD7cM6OUL29N537OyN6xzbfB5Zwx7vBA", // Firebase VAPID key
    });

    if (currentToken) {
      console.log(currentToken, "token");
      return currentToken;  // Return the token
    } else {
      console.log("No registration token available. Request permission to generate one.");
      return null;  // Return null if no token is available
    }
  } catch (err) {
    console.error("An error occurred while retrieving token: ", err);
    return null;  // Return null in case of an error
  }
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload, "listener");
      resolve(payload);
    });
  });


export const getNotificationPermission = async () => {
  // Notification.requestPermission().then((permission) => {
  //   debugger;
  //   if (permission === 'granted') {
  //     return true
  //   } else {
  //     console.log('Notifications are blocked. Please enable them in your browser settings.');
  //     alert('Please enable notifications in your browser settings.');
  //     return false;
  //   }
  // }).catch((e) => {
  //   console.log('Error in getting notification permission:', e);
  // })
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      return true
    } else {
      console.log('Notifications are blocked. Please enable them in your browser settings.');
      alert('Please enable notifications in your browser settings.');
      return false;
    }
  } catch (e) {
    console.log('Error in getting notification permission:', e);
  }
}


