export const usaStates = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" }
];


export const roles = {
  PATIENT: 'patient',
  PROVIDER: 'provider',
  PHARMACY: 'pharmacy'
}

export const accountSettingsTabs = {
  patient: [
    { label: 'My Profile', value: 'profile' },
    { label: 'My Address', value: 'address' },
    { label: 'My Password', value: 'security' },
    { label: 'Settings', value: 'setting' },
    { label: 'My Payment Methods', value: 'payment-methods' },
    // { label: 'Privacy Settings', value: 'privacy' },
    { label: 'My Invoices', value: 'biling' },
    { label: 'Notifications Tab', value: 'notifications-tab' },
  ],
  // provider: [
  //   { label: 'My Profile', value: 'profile' },
  //   { label: 'My Password', value: 'security' },
  //   { label: 'Professional Profile', value: 'professional' },
  //   { label: 'My Invoices', value: 'biling' },
  //   { label: 'Notifications Tab', value: 'notifications-tab' },
  // ],
  // pharmacy: [
  //   { label: 'My Profile', value: 'profile' },
  //   { label: 'My Password', value: 'security' },
  //   { label: 'Professional Profile', value: 'professional' },
  //   { label: 'My Invoices', value: 'biling' },
  //   { label: 'Notifications Tab', value: 'notifications-tab' },
  // ]
}

export const itemStatus = {
  PE: 'Pending',
  AP: 'Approved',
  RE: 'Rejected',
  CP: 'Completed',
}

export const trackingStatus = {
  UNFULFILLED: 'Cancelled',
  FULFILLED: 'paid',
  "PARTIALLY_FULFILLED": 'Pending',
  SCHEDULED: 'Pending',
  "ON HOLD": 'Pending',
}

export const stripeInvoiceStatus = {
  succeeded: 'paid',
  pending: 'Pending',
  failed: 'Cancelled',
  refund: 'Refund'
}

export const labResultsStatus = {
  completed: 'Completed',
  pending: 'Pending',
  failed: 'Cancelled',
  cancelled: 'Cancelled',
}

export const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export const getMonthNumber = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
}

export const binaryMCOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" }
]

export const irregularMCOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "Irregular", value: "irregular" },
]

export const otherMCOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "Others", value: "others" },
]

export const conditionMCOptions = [
  { label: "None", value: "none" },
  { label: "Mild", value: "mild" },
  { label: "Moderate", value: "moderate" },
  { label: "Severe", value: "severe" },
]

export const periodTypeOptions = [
  { label: "Premenopausal", value: "premenopausal" },
  { label: "Perimenopausal", value: "perimenopausal" },
  { label: "Postmenopausal", value: "postmenopausal" },
]

export const USATimezones = [
  { label: 'Central Timezone', value: 'America/Chicago' },
  { label: 'Eastern Timezone', value: 'America/New_York' },
  { label: 'Mountain Timezone', value: 'America/Denver' },
  { label: 'Pacific Timezone', value: 'America/Los_Angeles' },
  { label: 'Alaska Timezone', value: 'America/Anchorage' },
  { label: 'Hawaii Timezone', value: 'Pacific/Honolulu' }
]