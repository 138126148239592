import { Badge, IconButton, Menu, Stack, Typography, Divider } from '@mui/material'
import { useState } from 'react'

import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DropdownNotificationListing from './DropdownNotificationListing';
import Link from 'components/Link';
import useFcm from 'shared/hooks/useFcm';
import { appWhite } from 'theme/colors';
import { useDispatch, useSelector } from 'react-redux';
import { resetUnreadNotifications } from 'slices/user.slice';

const WrapperStyle = {
  top: "12px",
  '& .MuiPaper-root': {
    // boxShadow: "0px 8px 8px -4px #1018280A",
    width: '460px',
    // height: '465px',
    overflowY: 'auto',
    radius: '16px',
    // boxShadow: 'none',
    '& .MuiList-root': {
      height: '465px',
      padding: '0px!important',
      display: 'flex',
      flexDirection: 'column',
      gap: "12px",
    }
  }
}
const iconButtonStyle = {
  border: `1px solid #DDDFE4`,
  color: '#9E9E9E',
  borderRadius: '50%',
}
const NotificationsDropdown = () => {
  const dispatch = useDispatch();
  const unreadNotifications = useSelector(state => state.user.unreadNotifications) ?? [];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { updateFcmToken } = useFcm();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const resp = Notification.permission;
    Notification.requestPermission().then((permission) => {
      if (resp === 'granted' && permission === 'granted') {
        console.log('granted');
        // do nothing here
      } else if (permission === 'granted') {
        updateFcmToken();
      } else {
        console.log('Notifications are blocked. Please enable them in your browser settings.');
        alert('Please enable notifications in your browser settings.');
        return;
      }
    }).catch((e) => { })
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(resetUnreadNotifications());
  };

  // useEffect(() => {
  //   // Listen for incoming messages
  //   onMessageListener()
  //     .then((payload) => {
  //       console.log('Message received: ', payload);
  //       // Handle incoming messages here
  //     })
  //     .catch((err) => console.log('Message listener error: ', err));
  //   // debugger;
  //   // Cleanup the listener when the component unmounts
  //   // return () => unsubscribe();
  // }, []);


  return (
    <>
      <Badge badgeContent={unreadNotifications?.length} sx={{
        '& .MuiBadge-badge ': {
          backgroundColor: '#E3757A',
          top: '8px',
          right: '4px',
          color: appWhite
        }
      }}>
        <IconButton sx={iconButtonStyle} onClick={handleClick}>
          <NotificationsOutlinedIcon />
        </IconButton>
      </Badge>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock
        disableAutoFocusItem
        sx={WrapperStyle}
      >
        <div>
          <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography fontSize={18} color="#1E2B49" fontWeight={600}>Notifications</Typography>
              {/* <Badge badgeContent={6} color="primary" /> */}
            </Stack>
            <IconButton onClick={handleClose}> <CloseOutlinedIcon sx={{ color: '#1E2B49' }} /> </IconButton>
          </Stack>
          <Divider />
        </div>
        <DropdownNotificationListing gap={0.5}
        // unreadNotifications={unreadNotifications}
        />
        <div onClick={handleClose}>
          <Link
            textAlign="center"
            to="/settings?tab=notifications-tab"
            color={theme => theme.palette.text.appGrey}
            variant="body2" fontWeight={600}
          >
            See all
          </Link>
        </div>
      </Menu>
    </>
  )
}

export default NotificationsDropdown