// import { useLocation } from "react-router-dom";
import { Stack } from '@mui/material';
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { useLocation } from "react-router-dom";
import { withoutLayoutRoutes } from "shared/routes";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const sidebar = useSelector((state) => state.user.sidebar);

  const isWithoutLayoutRoute = (path) => {
    return withoutLayoutRoutes.some((route) => route.test(path));
  };

  return (
    <Stack>
      {isWithoutLayoutRoute(pathname) ?
        children :
        <>
          <Header />
          <Stack direction="row">
            <Sidebar />
            <Stack
              sx={{
                width: {
                  xs: "100%",
                  sm: sidebar ? "calc(100vw - 267px)" : "calc(100vw - 105px)",
                },
                backgroundColor: '#F6F8F9',
                height: "calc(100vh - 61px)",
                overflowY: "auto",
              }}>
              {children}
            </Stack>
          </Stack>
        </>
      }
    </Stack>
  );
};

export default Layout;
