import { Badge, IconButton, Menu, Stack, Typography, Divider, CircularProgress } from '@mui/material'
import { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { appBorderColor, appWhite } from 'theme/colors';
import DeleteLogo from 'assets/logos/DeleteLogo';
import ActionButtons from 'components/ModalBox/ActionButtons';
import useCart from 'shared/hooks/useCart';
import useDebounce from 'shared/hooks/useDebounce';
import { customAlert } from 'utils/helperFunctions';
import { useNavigate } from 'react-router-dom';
import { useCreateOrderMutation } from 'apis/supplementStore.api';

const WrapperStyle = {
  top: "12px",
  '& .MuiPaper-root': {
    // boxShadow: "0px 8px 8px -4px #1018280A",
    width: '460px',
    // height: '465px',
    overflowY: 'auto',
    radius: '16px',
    // boxShadow: 'none',
    '& .MuiList-root': {
      // height: '465px',
      padding: '0px!important',
      display: 'flex',
      flexDirection: 'column',
      gap: "12px",
    }
  }
}
const iconButtonStyle = {
  border: `1px solid #DDDFE4`,
  color: '#9E9E9E',
  borderRadius: '50%',
}
const SupplementCart = () => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const deleteItemId = useRef(null);
  const { cart, removeFromCart, updateCartQuantity, isRemoveCartItemLoading } = useCart();
  const [createOrder, { isLoading: isCreateOrderLoading }] = useCreateOrderMutation();
  const { estimatedCost: { totalAmount: { amount = '0' } = {} } = {}, lines: { edges = [] } = {} } = cart ?? {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateCartItem = ({ lineId, quantity }) => {
    updateCartQuantity({ lineId, quantity })
  }

  // const checkoutPage = () => {
  //   // if (edges?.length) {
  //     // window.open(checkoutUrl, '_self');
  //     // handleClose();
  //     // navigate("/supplement-checkout")
  //   // }
  // }

  const handleCreateOrder = () => {
    if (edges?.length) {
      createOrder().unwrap().then((resp) => {
        handleClose();
        navigate(`/supplement-checkout?draftId=${resp?.draftOrderCreate?.draftOrder?.id}`)
        // checkoutPage()
        // navigate(`/supplement-order-detail/${data?.createOrder?.order?.id}`)
      }).catch(e => console.log(e))
    }
  }

  const callback = () => {
    deleteItemId.current = null;
  }

  const deleteItem = ({ lineId }) => () => {
    deleteItemId.current = lineId;
    removeFromCart({ lineId, callback })();
  }

  return (
    <>
      <Badge badgeContent={edges?.length} sx={{
        '& .MuiBadge-badge ': {
          backgroundColor: '#E3757A',
          top: '8px',
          right: '4px',
          color: appWhite
        }
      }}>
        <IconButton sx={iconButtonStyle} onClick={handleClick}>
          <ShoppingCartOutlinedIcon />
        </IconButton>
      </Badge>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock
        disableAutoFocusItem
        sx={WrapperStyle}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2} borderBottom={`1px solid ${appBorderColor}`}>
          <Typography color="#1E2B49" fontSize={18} fontWeight={600}>Cart</Typography>
          <IconButton onClick={handleClose}> <CloseIcon fontSize='medium' sx={{ color: '#1D2939' }} /> </IconButton>
        </Stack>
        <Stack direction="column" gap={4}>
          <Stack px={3} direction="column" gap={4} maxHeight="220px" overflow="auto">
            {edges?.length === 0 ?
              <Typography py={2} fontWeight={600} textAlign="center" variant='body2' color="#666F82">Your cart is empty</Typography>
              : edges.map(({ node }) =>
                <CartItem
                  key={node?.id}
                  quantity={node?.quantity}
                  product={node}
                  removeFromCart={deleteItem}
                  updateCartItem={updateCartItem}
                  isRemoveCartItemLoading={isRemoveCartItemLoading}
                  selectedId={deleteItemId.current}
                />
              )}
          </Stack>
          <Stack px={3} direction="column" gap={4} >
            <Stack p={3} direction="column" gap={2} borderRadius="8px" sx={{ backgroundColor: "#FAFAFA" }}>
              <Typography fontWeight={500} variant='body2'>Billing Summary</Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='body2' fontWeight={600}>Total</Typography>
                <Typography variant='body2' fontWeight={600}>${parseFloat(amount).toFixed(2)}</Typography>
              </Stack>
            </Stack>
            <Divider />
          </Stack>
        </Stack>
        <ActionButtons
          confirmText='Checkout'
          // cancelText='Continue Shopping'
          hideCancel
          fullWidth={false}
          handleCancel={handleClose}
          sx={{
            justifyContent: 'end',
          }}
          handleConfirm={handleCreateOrder}
          isLoading={isCreateOrderLoading}
          disableConfirm={edges?.length === 0}
        />
      </Menu>
    </>
  )
}

export default SupplementCart


const CartItem = ({ product = {}, quantity: itemQuantity = 1, removeFromCart, updateCartItem, isRemoveCartItemLoading, selectedId }) => {
  const { merchandise, cost, id } = product;
  const { totalAmount: { amount = '0' } = {} } = cost ?? {};
  const { product: { title = '', productType, featuredImage } = {}, title: variant = '', quantityAvailable } = merchandise ?? {}
  const { url } = featuredImage ?? {}
  const [quantity, setQuantity] = useState(itemQuantity);
  const handleUpdate = useDebounce(updateCartItem, 500);
  const handleChange = (operator) => () => {
    if (operator === 'add' && quantity === quantityAvailable) {
      customAlert({ message: 'You have reached the maximum quantity available', severity: 'info' });
      return;
    }
    switch (operator) {
      case 'add':
        setQuantity(p => {
          handleUpdate({ lineId: id, quantity: p + 1 });
          return p + 1;
        });
        break;
      case 'minus':
        if (quantity === 0) return;
        setQuantity(p => {
          handleUpdate({ lineId: id, quantity: p - 1 });
          return p - 1;
        });
        break;
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={2}>
        <img
          height={99}
          width={94}
          style={{ borderRadius: '14px', backgroundColor: '#F1F2F4' }}
          // src="https://glossmeup.com/wp-content/uploads/2022/01/Rinklex30-min.png"
          src={url}
        />
        <Stack direction="column" gap={1}>
          <Typography fontWeight={700} variant="body2" >{title}</Typography>
          <Typography variant='caption' color="#666F82">{variant ?? productType}</Typography>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            padding="6px 16px"
            borderRadius="8px"
            width="fit-content"
            sx={{ backgroundColor: '#F7F8F9' }}
          >
            <IconButton onClick={handleChange("minus")} sx={{ padding: "0px" }}>
              <RemoveIcon fontSize='small' />
            </IconButton>
            <Typography fontWeight={500} variant='caption' col0r="#49546B">{quantity}</Typography>
            <IconButton onClick={handleChange("add")} sx={{ padding: "0px" }}>
              <AddIcon fontSize='small' />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="column" justifyContent="space-between" alignItems="center" py={1}>
        <Typography color="primary" variant='body2' fontWeight={500} >${parseFloat(amount).toFixed(2)}</Typography>
        {isRemoveCartItemLoading && selectedId === id ?
          <CircularProgress sx={{ padding: '8px' }} size={15} thickness={1.5} />
          : <IconButton onClick={removeFromCart({ lineId: id })}>
            <DeleteLogo />
          </IconButton>
        }
      </Stack>
    </Stack>
  )
}